export const darkGreen = '#385454';
export const darkBlue = '#242430';
export const xDarkBlue = '#1A1A22';
export const altWhite = '#FBFBFA';
export const brandOrange = '#FF7A64';
export const brandYellow = '#FFF06B';
export const brandBlue = '#4E6280';
export const logoBlue = '#3EA9F5';
export const brandPink = '#FF8BB5';
export const brandGreen = '#305555';
export const aqua = '#25BBB8';
export const amountGreen = '#00BC83';
export const red = '#EF3B3D';
export const grey = '#D2D2D2';
export const anotherGrey = '#88888C'; //TODO

export const darkGrey = '#34333B';
export const yellow = '#FFEF6B';
export const yellowLight = '#FFFCE2';
export const qrCode = '#1a1a22';
