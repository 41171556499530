import * as React from 'react';
import BugsnagStatic from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import debugLogger from './debugLogger';

let config;
if ((window as any).BUGSNAG_API_KEY)
  config = {
    apiKey: (window as any).BUGSNAG_API_KEY,
  };

try {
  const el = document.querySelector('meta[name="bugsnag"]');

  if (el != null) {
    config = JSON.parse(el.getAttribute('content') ?? '');
  }
} catch (_) {
  // no-op
}

const enabled = config?.apiKey != null;

export const Bugsnag = BugsnagStatic.start({
  apiKey: '0'.repeat(32),
  ...config,
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginReact()],
  logger: {...console, debug: debugLogger('Bugsnag')},

  onError() {
    if (!enabled) return false;
  },

  onSession() {
    if (!enabled) return false;
  },

  onBreadcrumb(breadcrumb) {
    if (breadcrumb.type === 'user' && breadcrumb.message === 'UI click' && breadcrumb.metadata.targetText != null)
      breadcrumb.metadata.targetText = '[REDACTED]';
  },
});

// Just be a no-op unless Bugsnag is configured. Bugsnag doesn't like being
// started with invalid creds, and won't provide an ErrorBoundary if it hasn't
// been started first.
export const ErrorBoundary = enabled ? BugsnagStatic.getPlugin('react')!.createErrorBoundary(React) : React.Fragment;
export default ErrorBoundary;
