import {useCallback} from 'react';

function useCopy(text: string, onCopy?: () => void) {
  return useCallback(() => {
    if (navigator.clipboard?.writeText) {
      navigator.clipboard.writeText(text);
    } else {
      // Safari < 13.1
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body?.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body?.removeChild(textArea);
    }

    onCopy && onCopy();
  }, [text]);
}

export default useCopy;
