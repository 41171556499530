import {useEffect, useRef} from 'react';

type CallbackType = () => CleanupType;
type CleanupType = void | (() => void);

export default function useInterval(callback: CallbackType, delay: number | null, shouldTick: boolean = true) {
  const savedCallback = useRef<Nullable<CallbackType>>();
  const callbackCleanup = useRef<CleanupType>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    if (!shouldTick) return;

    function tick() {
      if (savedCallback.current) {
        callbackCleanup.current = savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        if (callbackCleanup.current) callbackCleanup.current();
        clearInterval(id);
      };
    }
  }, [delay, shouldTick]);
}
