function APILogo({className}: {className: string}) {
  return (
    <svg className={className} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1989.2 1388.6">
      <path
        fill="#FF7A64"
        d="M1494.7,620.6c-1-35.8-7.9-67.9-21.2-98.1c-58.7-133-181.1-199.4-327.3-177.5c-23,3.4-45.8,9-67.9,16.5
        c-9-9.4-21.2-13.7-38.8-13.7h-85.7c-12.6,0-23.6,7.9-28,20.1l-9.4,26C722.6,927,610.3,1235,600.2,1261c-6.6,17-4.9,34.7,4.6,48.6
        c9.4,13.7,25.5,21.5,44.2,21.5l83.5-0.6h0.1c12.7,0,23.6-7.7,27.9-19.6c10.6-29.5,25.3-70.3,40.8-113.4l33.6-93.2
        c21.6-59.9,41.6-115.5,50-139l16.3-45.6c7.1,7.6,14.5,14.6,22.2,21.1c56.8,47.8,121,71.9,191.5,71.9c17.8,0,35.9-1.5,54.5-4.6
        c78.3-13,145.5-45.3,199.9-96.1C1455.6,831.2,1497.8,733.2,1494.7,620.6z M1208.1,814.9c-30.2,19.1-62.5,28.7-96,28.7h-0.7
        c-52.1-0.2-96.1-32.7-112.1-82.8c-2.7-8.4-4.7-17.6-6.2-28c-0.4-3-0.6-6.1-0.9-10.4l-0.1-1.1l-0.3-3.2c3-52.2,21.2-98.7,54.3-138.2
        c35.2-42.1,81.1-65.3,136.5-69c2.9-0.2,5.8-0.3,8.4-0.3c45.8,0,84.2,24.9,105.5,68.4c12.1,24.7,16.7,48.2,13.8,70l0,0
        C1300.3,722.5,1265.9,778.4,1208.1,814.9z"
      />
      <path
        fill="#FFF06B"
        d="M717.8,717.6V356.9H535.9v-87.7H354.7l-0.1,87.7H172.7v360.7H83.3v365.8h181.2v-272H627v272h178.6V717.6H717.8z
        M535.9,720.4H354.7l0.1-272.7h181.1L535.9,720.4L535.9,720.4z"
      />
      <g>
        <g>
          <path
            fill="#3EA9F5"
            d="M1548.3,1031.7c-14.2-3-30.7-4.9-46-10.3c-27.9-9.9-42-31.7-43.9-60.8c-2.4-36.7,6-72.1,18.4-105.9
        c22.6-61.9,47.1-123.2,71.7-184.4c23.9-59.5,49-118.6,73.5-177.9c1.7-4.2,3-8.7,4-13.1c4.2-18.4-8.7-30.6-27.1-25.9
        c-17.9,4.5-32.3,14.9-44.8,27.5c-21.7,21.9-42.3,45-63.6,67.3c-7.8,8.2-16.1,15.9-24.6,23.3c-7.1,6.2-14.9,6.4-22.5,0.4
        c-7.4-5.8-10.1-13.7-6.2-22c4.4-9.3,9.4-18.7,16-26.5c40.3-48,84.6-91.6,139.5-123.2c46.7-26.9,96.7-42.3,150.8-42.7
        c21.3-0.1,42.1,3.8,60.7,14.9c28.1,16.8,41.5,51.4,37,88.7c-4.9,41.3-21.6,78.8-36.1,116.9c-29.3,76.7-59,153.2-88.3,229.9
        c-8.5,22.2-16.6,44.6-24.4,67c-1.9,5.4-2.4,11.5-2.3,17.2c0.4,13.9,10.6,22.5,23.4,17.6c12.8-4.9,26.1-11.5,36-20.7
        c25.3-23.5,48.8-48.8,73.2-73.3c6.5-6.5,13.1-12.8,19.9-19c5.6-5.1,11.8-9.2,19.8-4.5c7.9,4.6,13.3,15.4,9.8,23.6
        c-4.7,11.2-10,22.4-17.2,32.1c-32.2,43.4-72.4,78.5-116.4,109.4c-41.5,29.2-85.8,53.1-135.4,65.3
        C1586.1,1026.7,1568.3,1028.4,1548.3,1031.7z"
          />
        </g>
        <polygon fill="#3EA9F5" points="1852,112 1895.8,291.1 1688.8,291.1 	" />
      </g>
    </svg>
  );
}

export default APILogo;
