import classnames from 'classnames';

type Props = {
  readonly className?: string;
};

const containerStyle = {
  backgroundColor: '#393944',
};

const shadedTabStyle = {
  color: '#7A7A81',
};

function AppTabs({className}: Props) {
  return (
    <div style={containerStyle} className={classnames('pl-6 py-3 rounded-md', className)}>
      <span className="pr-6 text-altWhite"> Up </span>
      <span className="pr-6" style={shadedTabStyle}>
        Activities
      </span>
      <span style={shadedTabStyle}> Paym </span>
    </div>
  );
}

export default AppTabs;
