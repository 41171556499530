import classnames from 'classnames';
import AnimatedTokenLogo from './AnimatedTokenLogo';

type Props = {
  className?: string;
};

function YourToken({className}: Props) {
  return (
    <div className={classnames('max-w-md', className)}>
      <div className="text-3xl">Your Personal Access Token</div>
      <div className="text-lg md:mt-8 mt-4">
        This token will allow you to access our API and retrieve some of your personal data. For full details on how to
        start using your token visit our{' '}
        <a href="https://developer.up.com.au/" className="text-brandOrange">
          Developer Docs
        </a>
        .
      </div>
      <AnimatedTokenLogo className="md:mt-16 mt-8" />
    </div>
  );
}

export default YourToken;
