import * as React from 'react';
import {useTokenContext} from 'src/features/publicApi/components/contexts/tokenContext';
import Button from 'src/features/publicApi/components/Button';
import useCopy from 'src/lib/useCopy';

const {useState} = React;

const buttonStyle = {
  width: 70,
};

const inputStyle = {
  backgroundColor: '#34333B',
  height: 45,
};

function CopyButton() {
  const [copied, setCopied] = useState(false);
  const {token} = useTokenContext();
  const onCopy = useCopy(token, () => {
    setCopied(true), setTimeout(() => setCopied(false), 5000);
  });
  return (
    <div className="flex justify-between items-center">
      <input className="w-32 mr-4 pl-4 bg-xDarkBlue rounded" value={token} style={inputStyle} readOnly />
      <Button
        style={buttonStyle}
        data-semantic="copy-access-token"
        data-token={token}
        onClick={onCopy}
        className="bg-brandOrange text-xDarkBlue"
      >
        {copied ? 'Copied!' : 'Copy'}
      </Button>
    </div>
  );
}

export default CopyButton;
