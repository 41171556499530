import classnames from 'classnames';
import AnimatedLogo from 'src/components/AnimatedLogo';
import UserInfo from './UserInfo';

type Props = {
  className?: string;
};

export default function Header({className}: Props) {
  return (
    <div
      className={classnames(
        'px-4 py-4 sm:px-8 sm:py-8 mb-4 sm:mb-0 flex flex-wrap items-center justify-between',
        className
      )}
    >
      <AnimatedLogo />
      <UserInfo />
    </div>
  );
}
