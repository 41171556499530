import * as React from 'react';
import Header from './Header';
import YourToken from './YourToken';
import {TokenContext} from './contexts/tokenContext';
import HowToUseSafely from './HowToUseSafely';
import backIcon from './back.svg';

const {useMemo} = React;

type Props = {
  token: string;
  tokenCreatedAt: string;
  tokenLastUsedAt: string;
  upName: Nullable<string>;
  shareableReferralUrl: Nullable<string>;
  customerName: string;
  initials: string;
  upsiderImageUrl: string;
};

function AuthenticatedRoot({
  token,
  tokenCreatedAt,
  tokenLastUsedAt,
  upName,
  shareableReferralUrl,
  customerName,
  initials,
  upsiderImageUrl,
}: Props) {
  const tokenContextValue = useMemo(
    () => ({
      token,
      tokenCreatedAt,
      tokenLastUsedAt,
      upName,
      shareableReferralUrl,
      customerName,
      initials,
      upsiderImageUrl,
    }),
    [token, tokenCreatedAt, tokenLastUsedAt, upName, shareableReferralUrl, initials, upsiderImageUrl, customerName]
  );
  return (
    <TokenContext.Provider value={tokenContextValue}>
      <Header />
      <div className="xl:pl-64 lg:pl-32 pl-16 xl:pr-32 lg:pr-16 pr-8 ">
        <div className="pb-8 text-brandOrange">
          <a href="https://developer.up.com.au/" className="inline-flex align-middle">
            <img src={backIcon} className="mr-2" />
            Back to Developer Docs
          </a>
        </div>
        <YourToken />
        <HowToUseSafely className="md:my-32 my-16" />
      </div>
    </TokenContext.Provider>
  );
}

export default AuthenticatedRoot;
