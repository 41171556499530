import classnames from 'classnames';
import {useTokenContext} from 'src/features/publicApi/components/contexts/tokenContext';
import swirl from './assets/swirl.png';

type Props = {
  readonly className?: string;
};

const centeredStyle = {
  top: '12%',
  left: '7%',
};

function UserInfo({className}: Props) {
  const {upName, upsiderImageUrl, initials, customerName} = useTokenContext();
  if (!upName && !customerName) return null;
  const showCustomerName = upName == '';
  return (
    <div className={classnames('flex flex-wrap items-center', className)}>
      <a className="mr-6 hidden sm:block" href="/getting_started">
        End session
      </a>
      {showCustomerName || (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none">
          <path
            d="M8.37567 4.68195C7.20599 5.04989 5.91543 4.74511 4.70247 4.97545C4.16224 5.0849 3.63855 5.295 3.16325 5.61087C4.93016 6.61267 6.67034 7.57753 8.43725 8.57934C9.06528 8.94131 9.69331 9.30328 10.3838 9.47926C7.82028 9.98322 5.24021 10.3865 2.64869 10.6573M6.04859 1.46695C5.75082 5.56811 5.45305 9.66927 5.11833 13.7972"
            stroke="#FFEF6B"
            strokeWidth="1.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <div className="text-brandOrange mr-2"> {showCustomerName ? customerName : upName} </div>
      <div className="relative flex-shrink-0">
        <img src={swirl} />
        {upsiderImageUrl ? (
          <img style={centeredStyle} className="absolute rounded-full w-8 h-8" src={upsiderImageUrl} />
        ) : (
          <div
            style={centeredStyle}
            className="absolute bg-altWhite rounded-full w-8 h-8 flex justify-center items-center"
          >
            {initials}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserInfo;
