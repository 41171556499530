import * as React from 'react';
import classnames from 'classnames';
import useInterval from 'src/lib/useInterval';
import useBreakpoint from 'src/lib/useBreakpoint';
import APILogo from './APILogo';
import CopyButton from './CopyButton';

const {useState} = React;

type Props = {
  readonly className?: string;
};

function YellowText({text, className, ...props}: {text: string; className?: string} & JSX.IntrinsicElements['div']) {
  return (
    <div {...props} className={classnames('text-brandYellow', className)}>
      {text.split('').map((letter, index) => (
        <span key={index}> {letter} &nbsp; &nbsp; </span>
      ))}
    </div>
  );
}

const numberOfStates = 3;

//TODO maybe a hidden message in here could be cool.
const LHSTexts = ['2XEAm2XEAmG', 'a2XEAm2XEAm', 'Fa2XEAm2XEA'];
const RHSTexts = ['2XEAm2XEAmG', 'a2XEAm2XEAm', 'Fa2XEAm2XEA'];
const BottomTexts = ['2XEAmXEAmGH1', 'a2XEAmXEAmGH', 'Fa2XEAmXEAmG'];

const containerStyle = {
  height: 420,
}; //A bit messy with the absolutely positioned text

function AnimatedTokenLogo({className}: Props) {
  const [textPosition, setTextPosition] = useState(0);
  const breakpoint = useBreakpoint();
  useInterval(
    () => {
      setTextPosition((textPosition + 1) % numberOfStates);
    },
    200,
    breakpoint !== 'xs'
  );

  //
  return (
    <div
      className={classnames('w-full flex flex-col justify-center items-center relative sm:-mb-16', className)}
      style={breakpoint !== 'xs' ? containerStyle : undefined}
    >
      <YellowText
        className="hidden sm:block absolute"
        style={{
          transform: 'translate3d(115px,-59px,0px)rotate(55deg)',
        }}
        text={LHSTexts[textPosition]}
      />
      <YellowText
        className=" hidden sm:block absolute"
        style={{
          transform: 'translate3d(-97px,-75px,0px)rotate(-55deg)',
        }}
        text={RHSTexts[textPosition]}
      />
      <APILogo className="w-32" />
      <CopyButton />
      <YellowText
        className="hidden sm:block mt-4 mb-4 sm:mb-0"
        style={{
          transform: 'translateX(-10px) rotate(180deg)',
        }}
        text={BottomTexts[textPosition]}
      />
    </div>
  );
}

export default AnimatedTokenLogo;
