// Pack for api.up.com.au
import ReactDOM from 'react-dom/client';
import BugsnagErrorBoundary from 'src/lib/bugsnag';
import APIBase from 'src/features/publicApi/APIBase';

const rootEl = document.getElementById('root');

if (rootEl) {
  const {config} = rootEl.dataset;
  const props = config ? JSON.parse(config) : {};

  ReactDOM.createRoot(rootEl).render(
    <BugsnagErrorBoundary>
      <APIBase {...props} />
    </BugsnagErrorBoundary>
  );
}
