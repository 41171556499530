import Header from './Header';
import ScanInstructions from './ScanInstructions';
import QRCode from './QRCode';
import backIcon from './back.svg';

export default function UnauthenticatedRoot() {
  return (
    <>
      <Header />
      <div className="xl:pl-64 lg:pl-32 md:pl-8 sm:px-8 px-4 pb-8">
        <div className="pb-8 text-brandOrange">
          <a href="https://developer.up.com.au/" className="inline-flex align-middle">
            <img src={backIcon} className="mr-2" />
            Back to Developer Docs
          </a>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-auto flex-wrap flex-col md:flex-row">
            <div className="text-3xl sm:text-3xl lg:text-4xl max-w-md lg:max-w-lg">
              Scan the QR Code to get your Personal Access Token
            </div>
            <QRCode className="mt-12 mb-24 md:my-0 md:ml-10 lg:ml-32 ml-0" />
          </div>
          <div>
            <ScanInstructions className="" />
          </div>
        </div>
      </div>
    </>
  );
}
