import * as React from 'react';

type TokenContextData = Readonly<{
  token: string;
  tokenCreatedAt: string;
  tokenLastUsedAt: string;
  upName: Nullable<string>;
  shareableReferralUrl: Nullable<string>;
  customerName: string;
  upsiderImageUrl?: Nullable<string>;
  initials: string;
}>;

const defaultValues = {
  token: '',
  tokenCreatedAt: '',
  tokenLastUsedAt: '',
  upName: null,
  shareableReferralUrl: null,
  customerName: '',
  upsiderImageUrl: null,
  initials: '',
};

export const TokenContext = React.createContext<TokenContextData>(defaultValues);

export function useTokenContext(): TokenContextData {
  return React.useContext(TokenContext);
}
