import * as React from 'react';
import * as ActionCable from '@rails/actioncable';
import QRCodeBase from 'qrcode.react';
import classnames from 'classnames';
import * as COLORS from 'src/lib/colors';
import {assertExhaustive} from 'src/lib/types';
import TriangleSpinner from './TriangleSpinner';

type Props = {
  className?: string;
};

enum PAYLOAD_TYPES {
  AUTHORISATION_RESPONSE = 'AUTHORISATION_RESPONSE',
  CHALLENGE_RESPONSE = 'CHALLENGE_RESPONSE',
  DISCONNECT = 'DISCONNECT',
}

type ChallengeResponsePayload = {
  type: PAYLOAD_TYPES.CHALLENGE_RESPONSE;
  data: {
    challenge: string;
    test?: boolean;
  };
};

type AuthorisationResponsePayload = {
  type: PAYLOAD_TYPES.AUTHORISATION_RESPONSE;
  data: {
    authorised: boolean;
  };
};

type DisconnectPayload = {
  type: PAYLOAD_TYPES.DISCONNECT;
};

type ChannelPayload = ChallengeResponsePayload | AuthorisationResponsePayload | DisconnectPayload;

const QR_CODE_SIZE = 180;
const URL_PREFIX = 'https://app.up.com.au/a/api?qrToken=';

export default function QRCode({className}: Props) {
  const [isCancelled, setCancelled] = React.useState(false);
  const [challengeToken, setChallengeToken] = React.useState<string | null>(null);
  const [isTestEnv, setIsTestEnv] = React.useState<boolean>(false);

  React.useEffect(() => {
    const consumer = ActionCable.createConsumer('/auth/websocket');
    const subscription = consumer.subscriptions.create('ApiLoginChallengeChannel', {
      received(payload: ChannelPayload) {
        switch (payload.type) {
          case PAYLOAD_TYPES.CHALLENGE_RESPONSE:
            setChallengeToken(payload.data.challenge);
            setIsTestEnv(!!payload.data.test);
            return;

          case PAYLOAD_TYPES.AUTHORISATION_RESPONSE:
            if (payload.data.authorised) {
              window.location.assign('/auth_granted');
            }

            return;

          case PAYLOAD_TYPES.DISCONNECT:
            setCancelled(true);
            cleanup();
            return;

          default:
            assertExhaustive(payload);
            // This should not happen...
            return;
        }
      },
    });

    const cleanup = () => {
      subscription.unsubscribe();
      consumer.disconnect();
    };

    window.addEventListener('beforeunload', cleanup);

    return cleanup;
  }, [setChallengeToken, setIsTestEnv, setCancelled]);

  const ChallengeToken = () => {
    if (isCancelled) {
      return (
        <div className="self-center text-center">
          <p className="p-4">Connected in another window. Refresh to reconnect here.</p>
        </div>
      );
    }

    if (challengeToken) {
      return (
        <div data-semantic="challenge-token" data-token={isTestEnv && challengeToken}>
          <QRCodeBase
            level="L"
            size={QR_CODE_SIZE}
            bgColor={COLORS.qrCode}
            fgColor={COLORS.brandOrange}
            value={URL_PREFIX + challengeToken}
            renderAs="svg"
          />
        </div>
      );
    }

    return (
      <div className="self-center">
        <TriangleSpinner />
      </div>
    );
  };

  return (
    <div className={classnames(className, 'self-start bg-qrCode p-4')}>
      <div
        className="flex justify-center"
        style={{
          height: QR_CODE_SIZE,
          width: QR_CODE_SIZE,
        }}
      >
        <ChallengeToken />
      </div>
    </div>
  );
}
