/**
 * Use at the end of a switch statement of an exhaustive chain of conditionals
 * to allow TypeScript to assert that all possible cases of a union/enum have
 * been handled.
 *
 * @param {*} value Value to be asserted as impossible
 * @param {string|boolean} throwMessage Error to be thrown if value is not handled. Won't throw by default.
 */
export function assertExhaustive(value: FutureAddedValue | never, throwMessage: string | true): never;
export function assertExhaustive(value: FutureAddedValue | never, throwMessage?: false): void;
export function assertExhaustive(
  _value: FutureAddedValue | never,
  throwMessage: string | boolean = false
): never | void {
  if (throwMessage !== false)
    throw new Error(typeof throwMessage === 'string' ? throwMessage : 'Reached unexpected case in exhaustive switch');
}
