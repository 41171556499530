import './api.css';
import AuthenticatedRoot from './components/AuthenticatedRoot';
import UnauthenticatedRoot from './components/UnauthenticatedRoot';

type Props = {
  token?: string;
  tokenCreatedAt?: string;
  tokenLastUsedAt?: string;
  upName?: Nullable<string>;
  shareableReferralUrl?: Nullable<string>;
  customerName?: string;
  upsiderImageUrl?: string;
  initials?: string;
};

export default function APIBase({
  token,
  tokenCreatedAt,
  tokenLastUsedAt,
  upName,
  shareableReferralUrl,
  customerName,
  upsiderImageUrl,
  initials,
}: Props) {
  return token ? (
    <AuthenticatedRoot
      token={token ?? ''}
      tokenCreatedAt={tokenCreatedAt ?? ''}
      tokenLastUsedAt={tokenLastUsedAt ?? ''}
      upName={upName ?? null}
      shareableReferralUrl={shareableReferralUrl ?? null}
      customerName={customerName ?? ''}
      upsiderImageUrl={upsiderImageUrl ?? ''}
      initials={initials ?? ''}
    />
  ) : (
    <UnauthenticatedRoot />
  );
}
