import * as React from 'react';
import Sprite from './assets/small.png';

type Props = {
  sprite: string;
  startFrame: number;
  totalFrames: number;
  framesPerRow: number;
  width: number;
  height: number;
};

const defaultProps = {
  sprite: Sprite,
  startFrame: 0,
  totalFrames: 23,
  framesPerRow: 5,
  width: 42,
  height: 38,
};

function TriangleSpinner(props: Props) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const rafRef = React.useRef<ReturnType<typeof requestAnimationFrame>>();
  const previousTimeRef = React.useRef(0);
  const frameRef = React.useRef(props.startFrame);
  const [sprite, setSprite] = React.useState<HTMLImageElement | null>(null);

  const loadSprite = React.useCallback(
    (src: string) => {
      const sprite = new Image();
      sprite.src = src;

      sprite.onload = () => setSprite(sprite);
    },
    [setSprite]
  );

  const getSpritePosition = React.useCallback(
    (frameIndex: number) => {
      const row = Math.floor(frameIndex / props.framesPerRow);
      const col = frameIndex % props.framesPerRow;
      const width = props.width * col;
      const height = props.height * row;
      return [width, height];
    },
    [props.width, props.height, props.framesPerRow]
  );

  React.useEffect(() => {
    const {current: canvas} = canvasRef;

    if (!sprite) return;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (context == null) return;

    const loop = (time: number) => {
      const deltaTime = time - previousTimeRef.current;

      if (deltaTime > 100) {
        const nextFrame = frameRef.current + 1 >= props.totalFrames ? 0 : frameRef.current + 1;
        const [frameX, frameY] = getSpritePosition(nextFrame);

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(sprite, frameX, frameY, props.width, props.height, 0, 0, props.width, props.height);
        frameRef.current = nextFrame;
        previousTimeRef.current = time;
      }

      rafRef.current = requestAnimationFrame(loop);
    };

    rafRef.current = requestAnimationFrame(loop);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [sprite, getSpritePosition, canvasRef, props.height, props.width, props.totalFrames]);

  React.useEffect(() => loadSprite(props.sprite), [props.sprite, loadSprite]);

  return <canvas ref={canvasRef} width={props.width} height={props.height} />;
}

TriangleSpinner.defaultProps = defaultProps;

export default React.memo(TriangleSpinner);
